/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

type CustomCarouselProps = {
  children: React.ReactNode;
  slidesToShow?: number;
  speed?: number;
  isDots?: boolean;
  autoplay?: boolean;
  className?: string;
  centerMode?: boolean;
  isSuggested?: boolean;
  isResponsive?: boolean;
};

const CustomCarousel = ({
  children,
  slidesToShow = 1,
  speed = 500,
  isDots = false,
  autoplay = false,
  className = '',
  centerMode = false,
  isSuggested = false,
  isResponsive = false,
}: CustomCarouselProps) => {
  const options = {
    align: centerMode ? 'center' : 'start',
    containScroll: 'trimSnaps',
    loop: true,
    ...(isResponsive 
      ? {
          slidesToScroll: 1,
          breakpoints: {
            '(min-width: 1536px)': { slidesToScroll: 1, slidesToShow: 7 },
            '(min-width: 1280px)': { slidesToScroll: 1, slidesToShow: 5 },
            '(min-width: 1100px)': { slidesToScroll: 1, slidesToShow: 4 },
            '(min-width: 767px)': { slidesToScroll: 1, slidesToShow: 2 },
            '(max-width: 766px)': { slidesToScroll: 1, slidesToShow: 1 },
          }
        }
      : { slidesToScroll: 1, slidesToShow })
  };
  
  const [emblaRef, emblaApi] = useEmblaCarousel(options as any);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );

  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on('select', onSelect);

    // Handle autoplay
    if (autoplay) {
      const autoplayInterval = setInterval(() => {
        if (emblaApi.canScrollNext()) {
          emblaApi.scrollNext();
        } else {
          emblaApi.scrollTo(0);
        }
      }, 3000);

      return () => clearInterval(autoplayInterval);
    }
  }, [emblaApi, onSelect, autoplay]);

  // Custom styling for navigation buttons
  const navigationButtonClass = `
    absolute top-1/2 -translate-y-1/2 z-10
    w-7 h-12 flex items-center justify-center
    bg-[#61616145] text-white
    hover:bg-[#0000002e] transition-colors
    disabled:opacity-50 disabled:cursor-not-allowed
    opacity-0 hover:opacity-100 transition-opacity
  `;

  return (
    <div className={`relative ${className}`}>
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="flex" style={{ backfaceVisibility: 'hidden' }}>
          {React.Children.map(children, (child) => (
            <div className="flex-[0_0_auto]" style={{ 
              width: `${100 / (isResponsive ? 1 : slidesToShow)}%`,
              paddingLeft: '10px'
            }}>
              {child}
            </div>
          ))}
        </div>
      </div>

      {/* Navigation Buttons */}
      <button
        type="button"
        className={`${navigationButtonClass} ${
          isSuggested ? 'left-6' : 'left-[14px]'
        }`}
        onClick={scrollPrev}
        disabled={!prevBtnEnabled}
      >
        <ArrowBackIosNewIcon />
      </button>

      <button
        type="button"
        className={`${navigationButtonClass} ${
          isSuggested ? 'right-[-5px]' : 'right-[-14px]'
        }`}
        onClick={scrollNext}
        disabled={!nextBtnEnabled}
      >
        <ArrowForwardIosIcon />
      </button>

      {/* Dots */}
      {isDots && (
        <div className="absolute bottom-[-20px] left-0 right-0 flex justify-center gap-2">
          {scrollSnaps.map((_, idx) => (
            <button
              key={idx}
              type="button"
              className={`transition-all duration-300 rounded-full ${
                idx === selectedIndex
                  ? 'w-4 h-4 bg-[#9E9E9E]'
                  : 'w-3 h-3 bg-[#c4c0c0]'
              }`}
              onClick={() => emblaApi?.scrollTo(idx)}
              aria-label={`Go to slide ${idx + 1}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomCarousel;